header {
    background-color:darkslateblue;
    padding: 12px;
    color: white;
    display: flex;
    justify-content: space-between;
}

a {
    text-decoration: none;
    color: inherit;
}

a :hover{
    color: rgba(255, 255, 255, 0.5);
}

header h1 {
    font-size: 20px;
    font-weight: 200;
}

.search {
    display: flex;
}

.search input {
    width: 15em;
    margin-right: 5px;
}

