button, .btn {
    border: 1px solid black;
    background: #2a913e;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7rem;
    padding: 2px 36px;
    border-radius: 3px;
    line-height: 22px;
    text-decoration: none;
}